const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\HomeRouteContent.tsx";import { useTranslation } from "@opendash/core";
import moment from "moment";
import * as Parse from "parse";
import React from "react";
import {
  getUserProfile,
  ListContainer,
  MetaIcon,
  PublicTransportList,
  RideOfferResultList,
  TaxiInfoList,
  TitleContainer,
  usePublicTransportNearby,
  useRideSearchLocal,
} from "..";

export function HomeRouteContent() {
  const t = useTranslation();

  const [pt] = usePublicTransportNearby();
  const offers = useRideSearchLocal();

  const name = React.useMemo(() => {
    const full = Parse.User.current().get("name");

    if (!full) {
      return "Anon";
    }

    return full.split(" ")[0];
  }, []);

  const greeting = React.useMemo(() => {
    const now = moment();

    const time = now.get("hour");

    if (time < 5) {
      return "night";
    } else if (time < 12) {
      return "morning";
    } else if (time < 18) {
      return "day";
    } else {
      return "evening";
    }
  }, []);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
        , t("rs:home.greeting", {
          context: greeting,
          name: name,
        })
      )
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}, t("rs:search.ride_offers_nearby"))
      , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
        , React.createElement(RideOfferResultList, { data: offers, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}} )
      )

      , !getUserProfile().publicTransportDisabled && (
        React.createElement(React.Fragment, null
          , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
            , React.createElement(MetaIcon, { icon: "haltestelle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} )
            , t("rs:search.public_transport_offers_nearby")
          )
          , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
            , React.createElement(PublicTransportList, { data: pt, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}} )
          )
        )
      )

      , !getUserProfile().otherTransportsDisabled && (
        React.createElement(React.Fragment, null
          , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
            , React.createElement(MetaIcon, { icon: "taxi", __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}} )
            , t("rs:search.cap_offers_nearby")
          )
          , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
            , React.createElement(TaxiInfoList, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 81}} )
          )
        )
      )
    )
  );
}
