const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\WeekdayInput.tsx";import { useTranslation } from "@opendash/core";
import { Button } from "antd";
import React from "react";


const style = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export function WeekdayInput({ value, setValue }) {
  const t = useTranslation();

  function toggleWeekday(day) {
    if (!value || !Array.isArray(value)) {
      return void setValue([day]);
    }

    if (value.includes(day)) {
      return void setValue(value.filter((x) => x !== day));
    } else {
      return void setValue([...value, day]);
    }
  }

  return (
    React.createElement('div', { style: style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
      , [1, 2, 3, 4, 5, 6, 0].map((i) => (
        React.createElement(Button, {
          key: i,
          size: "large",
          shape: "circle",
          type: value.includes(i) ? "primary" : "default",
          children: t(`rs:weekdays.day_${i}_label`),
          title: t("rs:ride.end_select"),
          onClick: (e) => toggleWeekday(i), __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
        )
      ))
    )
  );
}
