const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\RideSearchResultsDefault.tsx";import { useTranslation } from "@opendash/core";
import React from "react";
import {
  getUserProfile,
  ListContainer,
  MetaIcon,
  RideOfferResultList,
  TaxiInfoList,
  TitleContainer,
  usePublicTransportRoute,
  useRideSearch,
} from "..";
import { PublicTransportList } from "./PublicTransportList";

export function RideSearchResultsDefault({}) {
  const t = useTranslation();

  const [pt] = usePublicTransportRoute();
  const offers = useRideSearch();

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
        , React.createElement(MetaIcon, { icon: "auto3", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
        , t("rs:search.ride_offers")
      )
      , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
        , React.createElement(RideOfferResultList, { data: offers, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
      )

      , !getUserProfile().publicTransportDisabled && (
        React.createElement(React.Fragment, null
          , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
            , React.createElement(MetaIcon, { icon: "haltestelle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} )
            , t("rs:search.public_transport_offers")
          )
          , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
            , React.createElement(PublicTransportList, { data: pt, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}} )
          )
        )
      )

      , !getUserProfile().otherTransportsDisabled && (
        React.createElement(React.Fragment, null
          , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
            , React.createElement(MetaIcon, { icon: "taxi", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} )
            , t("rs:search.cap_offers")
          )
          , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
            , React.createElement(TaxiInfoList, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} )
          )
        )
      )
    )
  );
}
