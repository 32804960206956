const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\UserBlacklistList.tsx";import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, List, message, Modal } from "antd";
import moment from "moment";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React from "react";
import { UserBlacklist } from "..";

export function UserBlacklistList({}) {
  const t = useTranslation();

  const query = React.useMemo(
    () =>
      new Parse.Query(UserBlacklist)
        .include("target")
        // @ts-ignore // this is valid syntax
        .include("target.rsProfile")
        .equalTo("user", Parse.User.current()),
    []
  );

  const { result, loading, reload } = useParseQuery(query);

  return (
    React.createElement(List, {
      loading: loading,
      dataSource: result,
      renderItem: (item) => {
        function onDelete() {
          Modal.confirm({
            title: t("rs:user.blacklist.remove_confirm_title"),
            content: t("rs:user.blacklist.remove_confirm_content"),
            okText: t("rs:user.blacklist.remove_ok"),
            onOk: () => {
              item.destroy().then(
                (ok) => {
                  message.success(t("rs:user.blacklist.remove_success"));
                  reload();
                },
                (error) => {
                  console.error(error);
                  message.success(t("rs:user.blacklist.remove_error"));
                }
              );
            },
          });
        }

        return (
          React.createElement(List.Item, {
            actions: [
              React.createElement(Button, {
                type: "default",
                icon: React.createElement(Icon, { icon: "ai:undo", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}} ),
                children: t("rs:user.blacklist.remove_action"),
                title: t("rs:user.blacklist.remove_action_title"),
                onClick: onDelete, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
              ),
            ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
          
            , React.createElement(List.Item.Meta, {
              title: item.get("target").get("name"),
              description: t("rs:user.blacklist.added_at", {
                date: moment(item.updatedAt).format("DD.MM.YYYY"),
              }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
            )
          )
        );
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
    )
  );
}
