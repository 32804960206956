const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\RideSearchInput.tsx";import React from "react";
import {
  RideSearchInputDefault,
  RideSearchInputSimple,
  getUserProfile,
} from "..";

export function RideSearchInput({}) {
  const isAccessibleMode = getUserProfile().accessibilityUsePredefinedSearch;

  if (isAccessibleMode) {
    return React.createElement(RideSearchInputSimple, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}} );
  }

  return React.createElement(RideSearchInputDefault, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} );
}
