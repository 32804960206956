const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\RoutesMap.tsx";import { useElementSize } from "@opendash/core";
import buffer from "@turf/buffer";
import L from "leaflet/dist/leaflet";
import React from "react";








export function RoutesMap({ routes, route, places = [] }) {
  const map = React.useRef(null);
  const routeLayer = React.useRef(null);
  const routeBufferLayer = React.useRef(null);
  const placeLayer = React.useRef(null);
  const container = React.useRef(null);

  const { width, height } = useElementSize(container);

  // Karte in div rendern
  React.useEffect(() => {
    if (!container.current || map.current) {
      return;
    }

    // @ts-ignore
    const tiles = window.leafletTiles;

    map.current = L.map(container.current, {
      center: [50.867377, 8.026677],
      zoom: 17,
      layers: [L.tileLayer(...tiles)],
    });

    map.current.scrollWheelZoom.disable();
  }, [container.current]);

  React.useEffect(() => {
    try {
      map.current.invalidateSize();
      map.current.fitBounds(routeLayer.current.getBounds());
    } catch (error) {
      // console.error(error);
    }
  }, [map.current, width, height]);

  // Add Route Layer
  React.useEffect(() => {
    if (routeLayer.current) {
      routeLayer.current.clearLayers();
    }

    if (routes.length === 0) {
      return;
    }

    if (!routeLayer.current) {
      routeLayer.current = L.geoJSON(null, {
        style: { color: "#ff0000", weight: 5, opacity: 0.6 },
      });
    }

    for (const route of routes) {
      routeLayer.current.addData(route.points);
    }

    map.current.addLayer(routeLayer.current);
    map.current.fitBounds(routeLayer.current.getBounds());
  }, [map.current, routes]);

  // Add Route Buffer Layer
  React.useEffect(() => {
    if (routeBufferLayer.current) {
      routeBufferLayer.current.clearLayers();
    }

    if (routes.length === 0) {
      return;
    }

    if (!routeBufferLayer.current) {
      routeBufferLayer.current = L.geoJSON(null, {
        style: { color: "#ff0000", weight: 5, opacity: 0.2 },
      });
    }

    for (const route of routes) {
      routeBufferLayer.current.addData(
        buffer(route.points, 200, { units: "meters" })
      );
    }

    map.current.addLayer(routeBufferLayer.current);
  }, [map.current, routes]);

  // Add Marker Cluster Layer
  React.useEffect(() => {
    if (placeLayer.current) {
      placeLayer.current.clearLayers();
    }

    if (places.length === 0) {
      return;
    }

    if (!placeLayer.current) {
      // @ts-ignore
      placeLayer.current = L.markerClusterGroup({
        maxClusterRadius: 40,
      });
    }

    const markers = places.map((place) => {
      return {
        name: place.get("name"),
        latitude: place.get("location").latitude,
        longitude: place.get("location").longitude,
      };
    });

    markers.forEach((m) => {
      const latlong = new L.LatLng(m.latitude, m.longitude);
      const marker = L.marker(latlong, {
        title: m.name,
      });

      marker.bindPopup(m.name);
      placeLayer.current.addLayer(marker);
    });

    map.current.addLayer(placeLayer.current);
  }, [map.current, places]);

  // Add Marker Layer
  // React.useEffect(() => {
  //   if (placeLayer.current) {
  //     placeLayer.current.clearLayers();
  //   }

  //   if (places.length === 0) {
  //     return;
  //   }

  //   if (!placeLayer.current) {
  //     // @ts-ignore
  //     placeLayer.current = L.markerClusterGroup({
  //       maxClusterRadius: 40,
  //     });
  //   }

  //   const markers = places
  //     .map((place) => {
  //       return {
  //         name: place.get("name"),
  //         latitude: place.get("location").latitude,
  //         longitude: place.get("location").longitude,
  //       };
  //     })
  //     .map((marker) => {
  //       return L.marker([marker.latitude, marker.longitude]).bindPopup(
  //         marker.name
  //       );
  //     });

  //   placeLayer.current = L.layerGroup(markers);

  //   map.current.addLayer(placeLayer.current);
  // }, [map.current, places]);

  return React.createElement('div', { ref: container, style: { height: "100%", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}} );
}
