const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\AdminRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { AdminLayout } from "@opendash/core";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { AdminUsersRoute, useParseUser } from "..";

export function AdminRoute() {
  const user = useParseUser();
  const isAdmin = _optionalChain([user, 'optionalAccess', _ => _.get, 'call', _2 => _2("tenantAdmin")]);

  console.log("test");

  if (!user || !isAdmin) {
    console.log("Forbidden");
    return null;
  }

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      , React.createElement(Routes, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , React.createElement(Route, { path: "users", element: React.createElement(AdminUsersRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} )
        , React.createElement(Route, { path: "users/:tab", element: React.createElement(AdminUsersRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} )
      )
    )
  );
}
