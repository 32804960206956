const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\UserLanguageSelection.tsx";import {
  useLocalStorage,
  useOpenDashApp,
  useTranslation,
} from "@opendash/core";
import { changeLanguage } from "@opendash/i18n";
import { Select } from "antd";
import React from "react";

export function UserLanguageSelection() {
  const t = useTranslation();

  const app = useOpenDashApp();
  const [lang, setLang] = useLocalStorage("opendash:language");

  return (
    React.createElement('div', { style: { paddingTop: 24, paddingBottom: 24 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}, t("opendash:account.language.info"))
      , React.createElement(Select, {
        placeholder: t("opendash:account.language.placeholder"),
        defaultValue: lang,
        onChange: (nextLang) => {
          changeLanguage(nextLang);
          setLang(nextLang);
        },
        style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      
        , app.ui.languages.map(({ key, label }) => (
          React.createElement(Select.Option, { key: key, value: key, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
            , label
          )
        ))
      )
    )
  );
}
