const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\MessengerList.tsx";import { useTranslation } from "@opendash/core";
import { List } from "antd";
import React from "react";
import {

  ListContainer,
  MessengerListItem,
  TitleContainer,
  useChats,
} from "..";

export function MessengerList() {
  const t = useTranslation();
  const chats = useChats();

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}, t("rs:messenger.title"))
      , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , React.createElement(List, {
          dataSource: chats,
          renderItem: (chat) => {
            return React.createElement(MessengerListItem, { chat: chat, hasLink: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
        )
      )
    )
  );
}
