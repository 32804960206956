import "leaflet/dist/leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import "leaflet.heat/dist/leaflet-heat";

// // L.Icon.Default.imagePath = '.';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

window.leafletTiles = [
  "https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=c0f28d3534ea433c97b193c2c3ae3709",
];
