import Parse from "parse";

Parse.initialize("ride-sharing");
Parse.serverURL = "https://parse-ride-sharing.apps.openinc.dev/parse";
// Parse.serverURL = "https://parse-ride-sharing-omi.apps.openinc.dev/parse";
// Parse.serverURL = "http://192.168.178.83:1337/parse";

Parse.enableLocalDatastore();

Parse.LiveQuery.on("open", () => {
  console.log("socket connection established");
});

Parse.LiveQuery.on("close", () => {
  console.log("socket connection closed");
});

Parse.LiveQuery.on("error", (error) => {
  console.error(error);
});
