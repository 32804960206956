const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\HomeRoute.tsx";import React from "react";
import { Navigate } from "react-router";
import {
  HomeRouteContent,
  OfflineMessage,
  useIsOnline,
  getUserProfile,
} from "..";

export function HomeRoute() {
  const isOnline = useIsOnline();
  const isSimple = getUserProfile().homeDisabled;

  if (!isOnline) {
    return React.createElement(OfflineMessage, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} );
  }

  if (isSimple) {
    return React.createElement(Navigate, { to: "/ride-sharing/search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} );
  }

  return React.createElement(HomeRouteContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 22}} );
}
