const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\RideOfferOverview.tsx";import { useTranslation, useUrlParam } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Dropdown, List, Menu, message, Modal, Switch } from "antd";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React from "react";
import { FormatWeekdays, ListContainer, Offer, TitleContainer } from "..";

const containerStyle = {
  // padding: 24,
};

const createButtonStyle = {
  float: "right",
};

export function RideOfferOverview({}) {
  const t = useTranslation();
  const [, setCreateView] = useUrlParam("create", false, "json");

  const query = React.useMemo(
    () =>
      new Parse.Query(Offer)
        .include("route")
        // this is valid syntax for includes
        // which is not supported by typescript
        // @ts-ignore
        .include("route.start")
        // @ts-ignore
        .include("route.end")
        .equalTo("user", Parse.User.current()),
    []
  );

  const { result, loading, reload } = useParseQuery(query);

  React.useEffect(() => {
    if (!loading && result.length === 0) {
      setCreateView(true);
    }
  }, [result, loading]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        , React.createElement(Button, {
          type: "primary",
          shape: "circle",
          style: createButtonStyle,
          icon: React.createElement(Icon, { icon: "ai:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} ),
          title: t("rs:offer.create.action"),
          onClick: () => {
            setCreateView(true);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
        )

        , t("rs:offer.list.title")
      )

      , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
        , React.createElement(List, {
          loading: loading,
          dataSource: result,
          renderItem: (item) => {
            const onSwitch = () => {
              const nextValue = !item.get("active");

              item.set("active", nextValue);
              item.save().then(
                (ok) => {
                  if (nextValue) {
                    message.success(t("rs:offer.toggle.success_active"));
                  } else {
                    message.success(t("rs:offer.toggle.success_inactive"));
                  }

                  reload();
                },
                (error) => {
                  message.error(t("rs:offer.toggle.error"));
                }
              );
            };

            const onDelete = () => {
              Modal.confirm({
                title: t("rs:offer.delete.confirm_title"),
                content: t("rs:offer.delete.confirm_content"),
                okText: t("rs:offer.delete.ok"),
                okType: "danger",
                onOk: () => {
                  item.destroy().then(
                    (ok) => {
                      message.success(t("rs:offer.delete.success"));
                      reload();
                    },
                    (error) => {
                      console.error(error);
                      message.success(t("rs:offer.delete.error"));
                    }
                  );
                },
              });
            };

            const menu = (
              React.createElement(Menu, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
                , React.createElement(Menu.Item, { key: "toggle", onClick: onSwitch, __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
                  /* <Icon type="check" /> */
                  , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}, t("rs:offer.toggle.action"))
                  , React.createElement('div', { style: { paddingTop: 10 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
                    , React.createElement(Switch, {
                      checked: item.get("active"),
                      style: { margin: "0 auto", display: "block" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
                    )
                  )
                )
                , React.createElement(Menu.Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 118}} )
                , React.createElement(Menu.Item, { key: "delete", onClick: onDelete, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
                  , React.createElement(Icon, { icon: "ai:delete", __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}} )
                  , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}, t("rs:offer.delete.action"))
                )
                , React.createElement(Menu.Item, { key: "edit", disabled: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
                  , React.createElement(Icon, { icon: "ai:edit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}} )
                  , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}, t("rs:offer.edit.action"))
                )
              )
            );

            return (
              React.createElement(List.Item, {
                actions: [
                  // <Switch
                  //   checked={item.get("active")}
                  //   onChange={onSwitch}
                  //   style={{ margin: "0 auto", display: "block" }}
                  // />,
                  React.createElement(Dropdown, {
                    overlay: menu,
                    trigger: ["click"],
                    children: 
                      React.createElement(Button, {
                        type: "default",
                        shape: "circle",
                        icon: React.createElement(Icon, { icon: "ai:more", __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
                      )
                    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
                  ),
                ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
              
                , React.createElement(List.Item.Meta, {
                  title: `${
                    !item.get("active") ? t("rs:offer.list.draft") + " " : ""
                  }${
                    item.get("route").get("start").get("title") ||
                    item.get("route").get("start").get("description")
                  } → ${
                    item.get("route").get("end").get("title") ||
                    item.get("route").get("end").get("description")
                  }`,
                  description: 
                    React.createElement(React.Fragment, null
                      , React.createElement(FormatWeekdays, { days: item.get("weekdays"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}} )
                      , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 164}}, " @ "  , item.get("time"))
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
                )
              )
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        )
      )
      , React.createElement('div', { style: { textAlign: "center", padding: 24 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}
        , React.createElement(Button, {
          type: "primary",
          size: "large",
          children: t("rs:offer.create.action"),
          onClick: () => {
            setCreateView(true);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}
        )
      )
    )
  );
}
