const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\RideSearchRoute.tsx";import { useUrlParam } from "@opendash/core";
import React from "react";
import {
  OfflineMessage,
  RideSearchInput,
  RideSearchResults,
  useIsOnline,
} from "..";

export function RideSearchRoute({}) {
  const [view, setView] = useUrlParam("view", null);
  const isOnline = useIsOnline();

  if (!isOnline) {
    return React.createElement(OfflineMessage, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} );
  }

  if (view === "results") {
    return React.createElement(RideSearchResults, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} );
  }

  return React.createElement(RideSearchInput, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 22}} );
}
