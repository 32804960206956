const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\RideOfferResultList.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation, useUrlParam } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Avatar, Button, List } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import {
  getProfileImage,
  ListEmpty,

  RideOfferRequest,
  useChats,
} from "..";

export function RideOfferResultList({ data }) {
  const t = useTranslation();
  const navigate = useNavigate();
  const chats = useChats();
  const [selectedOffer, setSelectedOffer] = useUrlParam("offer", null);

  if (data.length === 0) {
    return React.createElement(ListEmpty, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, t("rs:search.results.offers_empty"));
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(RideOfferRequest, {
        visible: !!selectedOffer,
        offerId: selectedOffer,
        onClose: () => {
          setSelectedOffer(null);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
      )
      , React.createElement(List, {
        dataSource: data,
        renderItem: (offer) => {
          const user = offer.get("user");
          const avatar = getProfileImage(user);
          const chat = chats.find((c) => _optionalChain([c, 'access', _ => _.get, 'call', _2 => _2("offer"), 'optionalAccess', _3 => _3.id]) === offer.id);

          const onClick = () => {
            if (chat) {
              navigate("/ride-sharing/messenger?chat=" + chat.id);
            } else {
              setSelectedOffer(offer.id);
            }
          };

          return (
            React.createElement(List.Item, {
              onClick: onClick,
              actions: 
                chat
                  ? [React.createElement(Button, { children: t("rs:search.request_open"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )]
                  : [
                      React.createElement(Button, {
                        type: "primary",
                        children: t("rs:search.request_create"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
                      ),
                    ]
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
            
              , React.createElement(List.Item.Meta, {
                avatar: 
                  React.createElement(Avatar, {
                    icon: avatar ? undefined : React.createElement(Icon, { icon: "ai:user", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} ),
                    src: avatar ? avatar : undefined,
                    size: "large", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
                  )
                ,
                title: t("rs:offer.results.title", { name: user.get("name") }),
                description: t("rs:offer.results.description", {
                  time: offer.get("time"),
                }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
              )
            )
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
      )
    )
  );
}
