const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\UserProfileImage.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { message, Spin, Upload } from "antd";
import Parse from "parse";
import React from "react";

export function UserProfileImage() {
  const t = useTranslation();
  const profile = Parse.User.current().get("rsProfile");
  const image = _optionalChain([profile, 'optionalAccess', _ => _.get, 'call', _2 => _2("image"), 'optionalAccess', _3 => _3.url, 'call', _4 => _4()]);

  const [state, setState] = React.useState({
    loading: false,
    file: null,
    imagePreview: "",
  });

  const beforeUpload = React.useCallback(
    (file) => {
      if (!(file.type === "image/jpeg" || file.type === "image/png")) {
        message.error(t("rs:profile.image.error_type"));

        return false;
      }

      if (!(file.size / 1024 / 1024 < 2)) {
        message.error(t("rs:profile.image.error_size"));

        return false;
      }

      setState({
        loading: true,
        file: null,
        imagePreview: "",
      });

      const parseFile = new Parse.File(
        file.type.replace("/", "."),
        file,
        file.type
      );

      parseFile
        .save()
        .then(() =>
          profile.save({
            image: parseFile,
          })
        )
        .then(
          () => {
            setState({
              loading: false,
              file,
              imagePreview: parseFile.url(),
            });
          },
          (error) => {
            console.error(error);
            message.error(t("rs:profile.image.error_upload"));
          }
        );

      return false;
    },
    [setState]
  );

  return (
    React.createElement('div', { style: { paddingTop: 24, paddingBottom: 24 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
      , React.createElement(Upload, {
        listType: "picture-card",
        fileList: state.file ? [state.file] : [],
        showUploadList: false,
        beforeUpload: beforeUpload, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
      
        , state.imagePreview || image ? (
          React.createElement('img', {
            src: state.imagePreview || image,
            alt: t("rs:profile.image.alt_text"),
            style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
          )
        ) : (
          React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
            , state.loading ? React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 86}} ) : React.createElement(Icon, { icon: "fa:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}} )
            , React.createElement('div', { style: { marginTop: 8 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}, "Upload")
          )
        )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
        , image
          ? t("rs:profile.image.replace")
          : t("rs:profile.image.description")
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}, t("rs:profile.image.disclaimer"))
    )
  );
}
