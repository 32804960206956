import Parse from "parse";
import React from "react";
import {
  createParsePolygonFromRoute,

  Place,
  useParseQuery,
} from "..";








export function usePlacesNearRoute(
  route
) {
  const polygon = React.useMemo(
    () => createParsePolygonFromRoute(route),
    [route]
  );

  const query = React.useMemo(
    () =>
      polygon
        ? new Parse.Query(Place)
            .equalTo("enabled", true)
            .limit(1000)
            .withinPolygon("location", polygon)
        : null,
    [polygon]
  );

  return useParseQuery(query);
}
