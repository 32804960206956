const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\IntroRoute.tsx";import { useTranslation, } from "@opendash/core";
import { Button, Tabs } from "antd";
import React from "react";
import Parse from "parse";
import {
  ListContainer,




  TitleContainer,

} from "..";
import { useNavigate } from "react-router";

const containerStyle = {
  padding: 20,
};

const buttonStyle = {
  marginBottom: 20,
  width: "100%",
};

const genderOptions = [
  {
    value: "female",
    label: "parse-custom:classes.RideSharingProfile.fields.gender_female",
  },
  {
    value: "male",
    label: "parse-custom:classes.RideSharingProfile.fields.gender_male",
  },
  {
    value: "other",
    label: "parse-custom:classes.RideSharingProfile.fields.gender_other",
  },
  {
    value: "nv",
    label: "parse-custom:classes.RideSharingProfile.fields.gender_nv",
  },
];

export const IntroRoute = React.memo(() => {
  const t = useTranslation();
  const navigate = useNavigate();

  const user = Parse.User.current();
  const name = user.get("name");
  const profile = user.get("rsProfile");

  const [tab, setTab] = React.useState("1");

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
        , t("rs:intro.wellcome", {
          name: name,
        })
      )
      , React.createElement(ListContainer, { style: containerStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        , React.createElement(Tabs, { renderTabBar: () => null, activeKey: tab, onChange: setTab, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
          , React.createElement(Tabs.TabPane, { key: "1", tab: "Tab 1" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
            , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}, t("rs:intro.start"))
            , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}, t("rs:intro.driver_question"))

            , React.createElement(Button, {
              type: "primary",
              size: "large",
              style: buttonStyle,
              children: t("rs:intro.driver_no"),
              onClick: () => {
                profile
                  .save({ showIntro: false, offerDisabled: true })
                  .then(() => {
                    setTab("2");
                  });
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
            )
            , React.createElement(Button, {
              type: "primary",
              size: "large",
              style: buttonStyle,
              children: t("rs:intro.driver_yes"),
              onClick: () => {
                profile
                  .save({ showIntro: false, offerDisabled: false })
                  .then(() => {
                    setTab("2");
                  });
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
            )
          )
          , React.createElement(Tabs.TabPane, { key: "2", tab: "Tab 2" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
            , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}, t("rs:intro.gender_question"))

            , genderOptions.map(({ value, label }) => (
              React.createElement(Button, {
                key: value,
                type: value === "nv" ? "default" : "primary",
                size: "large",
                style: buttonStyle,
                children: t(label),
                onClick: () => {
                  profile.save({ showIntro: false, gender: value }).then(() => {
                    setTab("3");
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
              )
            ))
          )
          , React.createElement(Tabs.TabPane, { key: "3", tab: "Tab 3" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
            , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}, t("rs:intro.end"))
            , React.createElement(Button, {
              type: "primary",
              size: "large",
              style: buttonStyle,
              children: t("rs:intro.next_home"),
              onClick: () => {
                navigate("/ride-sharing/home");
                window.location.reload();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
            )

            , React.createElement(Button, {
              type: "primary",
              size: "large",
              style: buttonStyle,
              children: t("rs:intro.next_search"),
              onClick: () => {
                navigate("/ride-sharing/search");
                window.location.reload();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
            )

            , !profile.get("offerDisabled") && (
              React.createElement(Button, {
                type: "primary",
                size: "large",
                style: buttonStyle,
                children: t("rs:intro.next_offer"),
                onClick: () => {
                  navigate("/ride-sharing/offer");
                  window.location.reload();
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
              )
            )

            , React.createElement(Button, {
              type: "primary",
              size: "large",
              style: buttonStyle,
              children: t("rs:intro.next_settings"),
              onClick: () => {
                navigate("/ride-sharing/profile");
                window.location.reload();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
            )
          )
        )
      )
      , React.createElement('div', { style: containerStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
        , tab !== "1" && (
          React.createElement(Button, {
            style: buttonStyle,
            children: t("rs:intro.back_button"),
            onClick: () => {
              setTab((parseInt(tab) - 1).toString());
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 164}}
          )
        )

        , React.createElement(Button, {
          style: buttonStyle,
          children: t("rs:intro.skip_button"),
          onClick: () => {
            navigate("/ride-sharing/home");
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}
        )
      )
    )
  );
});
