const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\AdminUsersList.tsx";import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Avatar, Button, List, message } from "antd";
import Parse from "parse";
import React from "react";
import { Link } from "react-router-dom";
import { getProfileImage, useParseQuery } from "..";

export function AdminUsersList({ query, active }) {
  const t = useTranslation();
  const users = useParseQuery(active && query);

  React.useEffect(() => {
    if (active && !users.loading) {
      users.reload();
    }
  }, [active]);

  if (!active) {
    return null;
  }

  return (
    React.createElement(List, {
      loading: users.loading,
      dataSource: users.result,
      renderItem: (user) => {
        const actions = [];

        if (!user.get("tenantVerified")) {
          actions.push(
            React.createElement(Button, {
              key: "check",
              icon: React.createElement(Icon, { icon: "ai:check", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} ),
              title: t("rs:admin.users.verify_tooltip"),
              type: "default",
              size: "small",
              onClick: () => {
                user.set("tenantVerified", true);

                user.save().then(
                  (ok) => {
                    message.success(t("rs:admin.users.verify_success"));
                    users.reload();
                  },
                  (error) => {
                    message.error(t("rs:admin.users.verify_error"));
                  }
                );
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
            )
          );
        }

        if (user.get("tenantVerified")) {
          actions.push(
            React.createElement(Button, {
              key: "close",
              icon: React.createElement(Icon, { icon: "ai:close", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}} ),
              title: t("rs:admin.users.unverify_tooltip"),
              type: "default",
              size: "small",
              disabled: Parse.User.current().id === user.id,
              onClick: () => {
                user.set("tenantVerified", false);

                user.save().then(
                  (ok) => {
                    message.success(t("rs:admin.users.unverify_success"));
                    users.reload();
                  },
                  (error) => {
                    message.error(t("rs:admin.users.unverify_error"));
                  }
                );
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
            )
          );
        }

        if (!user.get("rsBanned")) {
          actions.push(
            React.createElement(Button, {
              key: "lock",
              icon: React.createElement(Icon, { icon: "ai:lock", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}} ),
              title: t("rs:admin.users.ban_tooltip"),
              type: "default",
              size: "small",
              disabled: Parse.User.current().id === user.id,
              onClick: () => {
                user.set("rsBanned", true);

                user.save().then(
                  (ok) => {
                    message.success(t("rs:admin.users.ban_success"));
                    users.reload();
                  },
                  (error) => {
                    message.error(t("rs:admin.users.ban_error"));
                  }
                );
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
            )
          );
        }

        if (user.get("rsBanned")) {
          actions.push(
            React.createElement(Button, {
              key: "unlock",
              icon: React.createElement(Icon, { icon: "ai:unlock", __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}} ),
              title: t("rs:admin.users.unban_tooltip"),
              type: "default",
              size: "small",
              onClick: () => {
                user.set("rsBanned", false);

                user.save().then(
                  (ok) => {
                    message.success(t("rs:admin.users.unban_success"));
                    users.reload();
                  },
                  (error) => {
                    message.error(t("rs:admin.users.unban_error"));
                  }
                );
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
            )
          );
        }

        if (!user.get("tenantAdmin")) {
          actions.push(
            React.createElement(Button, {
              key: "plus",
              icon: React.createElement(Icon, { icon: "ai:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}} ),
              title: t("rs:admin.users.admin_tooltip"),
              type: "default",
              size: "small",
              onClick: () => {
                user.set("tenantAdmin", true);

                user.save().then(
                  (ok) => {
                    message.success(t("rs:admin.users.admin_success"));
                    users.reload();
                  },
                  (error) => {
                    message.error(t("rs:admin.users.admin_error"));
                  }
                );
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
            )
          );
        }

        if (user.get("tenantAdmin")) {
          actions.push(
            React.createElement(Button, {
              key: "minus",
              icon: React.createElement(Icon, { icon: "ai:minus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 161}} ),
              title: t("rs:admin.users.unadmin_tooltip"),
              type: "default",
              size: "small",
              disabled: Parse.User.current().id === user.id,
              onClick: () => {
                user.set("tenantAdmin", false);

                user.save().then(
                  (ok) => {
                    message.success(t("rs:admin.users.unadmin_success"));
                    users.reload();
                  },
                  (error) => {
                    message.error(t("rs:admin.users.unadmin_error"));
                  }
                );
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}
            )
          );
        }

        const avatar = getProfileImage(user);

        return (
          React.createElement(List.Item, { actions: actions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 186}}
            , React.createElement(List.Item.Meta, {
              avatar: 
                React.createElement(Avatar, {
                  icon: avatar ? undefined : React.createElement(Icon, { icon: "ai:user", __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}} ),
                  src: avatar ? avatar : undefined,
                  size: "large", __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}
                )
              ,
              title: 
                React.createElement(Link, { to: `/users/${user.id}/profile`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 196}}, user.get("name"))
              ,
              description: user.get("username"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 187}}
            )
          )
        );
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
    )
  );
}
