const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\RideSearchInputDefault.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation, useUrlParam } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Alert, Button, DatePicker } from "antd";
import moment from "moment";
import React from "react";
import {
  FormElement,
  LocationInput,
  MetaIcon,
  TimeInput,
  useRideSearchDate,
  useRideSearchEnd,
  useRideSearchStart,
  useRideSearchTime,
} from "..";

import distance from "@turf/distance";

const listButtonStyle = {
  margin: "0 auto",
  display: "block",
};

const containerStyle = {
  padding: 24,
};

const switchButtonStyle = {
  display: "block",
  zIndex: 1,
  marginTop: -28,
  marginBottom: -4,
  marginLeft: "auto",
  marginRight: 0,
};

const searchButtonStyle = {
  width: "100%",
  // marginTop: 14,
  marginBottom: 24,
};

const dividerLabelStyle = {
  fontSize: 14,
  fontWeight: 300,
  color: "inherit",
};

const inputWithLabelStyle = {
  width: "100%",
};

export function RideSearchInputDefault({}) {
  const t = useTranslation();

  const [view, setView] = useUrlParam("view", null);

  const [start, setStart] = useRideSearchStart();
  const [end, setEnd] = useRideSearchEnd();
  const [time, setTime] = useRideSearchTime();
  const [date, setDate] = useRideSearchDate();

  const isTooClose = React.useMemo(() => {
    const startLatitude = _optionalChain([start, 'optionalAccess', _ => _.get, 'call', _2 => _2("location"), 'optionalAccess', _3 => _3.latitude]);
    const startLongitude = _optionalChain([start, 'optionalAccess', _4 => _4.get, 'call', _5 => _5("location"), 'optionalAccess', _6 => _6.longitude]);
    const endLatitude = _optionalChain([end, 'optionalAccess', _7 => _7.get, 'call', _8 => _8("location"), 'optionalAccess', _9 => _9.latitude]);
    const endLongitude = _optionalChain([end, 'optionalAccess', _10 => _10.get, 'call', _11 => _11("location"), 'optionalAccess', _12 => _12.longitude]);

    const valid =
      startLatitude && startLongitude && endLatitude && endLongitude && true;

    if (!valid) {
      return false;
    }

    const a = [startLatitude, startLongitude];
    const b = [endLatitude, endLongitude];

    const distanceMeters = distance(a, b, { units: "meters" });

    return distanceMeters < 100;
  }, [
    _optionalChain([start, 'optionalAccess', _13 => _13.get, 'call', _14 => _14("location"), 'optionalAccess', _15 => _15.latitude]),
    _optionalChain([start, 'optionalAccess', _16 => _16.get, 'call', _17 => _17("location"), 'optionalAccess', _18 => _18.longitude]),
    _optionalChain([end, 'optionalAccess', _19 => _19.get, 'call', _20 => _20("location"), 'optionalAccess', _21 => _21.latitude]),
    _optionalChain([end, 'optionalAccess', _22 => _22.get, 'call', _23 => _23("location"), 'optionalAccess', _24 => _24.longitude]),
  ]);

  return (
    React.createElement('div', { style: containerStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
      , React.createElement(MetaIcon, { icon: "laufen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}} )
      , React.createElement(FormElement, { label: t("rs:ride.start_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
        , React.createElement(LocationInput, {
          style: inputWithLabelStyle,
          label: t("rs:ride.start_select"),
          urlParam: "select_start",
          location: start,
          setLocation: (location) => setStart(location), __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
        )
      )

      , React.createElement(Button, {
        size: "large",
        shape: "circle",
        type: "primary",
        style: switchButtonStyle,
        icon: React.createElement(Icon, { icon: "ai:retweet", __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}} ),
        title: t("rs:search.switch"),
        disabled: !start || !end,
        onClick: (e) => {
          setStart(end);
          setEnd(start);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
      )

      , React.createElement(MetaIcon, { icon: "zielen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}} )
      , React.createElement(FormElement, { label: t("rs:ride.end_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
        , React.createElement(LocationInput, {
          style: inputWithLabelStyle,
          label: t("rs:ride.end_select"),
          urlParam: "select_end",
          location: end,
          setLocation: (location) => setEnd(location), __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
        )
      )

      , isTooClose && (
        React.createElement(Alert, {
          type: "error",
          style: searchButtonStyle,
          message: t("rs:ride.start_end_too_close"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 128}}
        )
      )

      , React.createElement(MetaIcon, { icon: "kalender", __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}} )
      , React.createElement(FormElement, { label: t("rs:ride.date_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
        , React.createElement(DatePicker, {
          size: "large",
          style: inputWithLabelStyle,
          allowClear: false,
          format: "dddd, D. M. YYYY"   ,
          disabledDate: (current) =>
            current.isBefore(moment().subtract(1, "day"))
          ,
          defaultValue: 
            moment(date).isBefore(moment()) ? moment() : moment(date)
          ,
          onChange: (date) => {
            setDate(date.startOf("day").valueOf());
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
        )
      )

      , React.createElement(MetaIcon, { icon: "uhrweiss", __self: this, __source: {fileName: _jsxFileName, lineNumber: 154}} )
      , React.createElement(FormElement, { label: t("rs:ride.time_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}
        , React.createElement(TimeInput, { style: inputWithLabelStyle, time: time, setTime: setTime, __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}} )
      )

      , React.createElement(Button, {
        size: "large",
        type: "primary",
        style: searchButtonStyle,
        disabled: !start || !end || isTooClose,
        icon: React.createElement(MetaIcon, { icon: "lupe", button: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 164}} ),
        children: t("rs:search.action_label"),
        title: 
          !start || !end
            ? t("rs:search.action_tooltip_disabled")
            : t("rs:search.action_tooltip")
        ,
        onClick: (e) => {
          setView("results");
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}
      )

      /* <Divider style={dividerLabelStyle} children={t("search.highlights")} />

      <p>...</p> */

      /* <Divider style={dividerLabelStyle} children={t("rs:search.label")} />

      <Button
        // size="large"
        type="default"
        style={searchButtonStyle}
        children={t("search.list.action")}
        onClick={(e) => {
          setView("list");
        }}
      />

      <Button
        // size="large"
        type="default"
        style={searchButtonStyle}
        children={t("search.create.action")}
        onClick={(e) => {
          setView("create");
        }}
      /> */
    )
  );
}
