const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\AdminUsersRoute.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } }import Parse from "parse";
import React from "react";
import { useParams } from "react-router-dom";
import { AdminUsersList } from "..";

const unverifiedQuery = new Parse.Query(Parse.User)
  .equalTo("tenantVerified", false)
  .notEqualTo("rsBanned", true)
  .notEqualTo("tenantAdmin", true)
  .include("rsProfile");

const verifiedQuery = new Parse.Query(Parse.User)
  .equalTo("tenantVerified", true)
  .notEqualTo("rsBanned", true)
  .notEqualTo("tenantAdmin", true)
  .include("rsProfile");

const bannedQuery = new Parse.Query(Parse.User)
  .equalTo("rsBanned", true)
  .notEqualTo("tenantAdmin", true)
  .include("rsProfile");

const adminsQuery = new Parse.Query(Parse.User)
  .equalTo("tenantAdmin", true)
  .include("rsProfile");

export function AdminUsersRoute() {
  console.log("test 2");
  let { tab } = useParams();

  tab = _nullishCoalesce(tab, () => ( "unverified"));

  return (
    React.createElement(React.Fragment, null
      , React.createElement(AdminUsersList, { query: unverifiedQuery, active: tab === "unverified", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}} )
      , React.createElement(AdminUsersList, { query: verifiedQuery, active: tab === "verified", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}} )
      , React.createElement(AdminUsersList, { query: bannedQuery, active: tab === "banned", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}} )
      , React.createElement(AdminUsersList, { query: adminsQuery, active: tab === "admins", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}} )
    )
  );
}
