const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\MessengerListItem.tsx";import { useTranslation, useUrlParam } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Avatar, Button, Dropdown, List, Menu, message, Modal } from "antd";
import moment from "moment";
import Parse from "parse";
import React from "react";
import { getProfileImage, UserBlacklist, UserReport } from "..";

export function MessengerListItem({
  chat,
  hasLink = false,
}


) {
  const t = useTranslation();
  const [, setChat] = useUrlParam("chat", null, "string");

  const isMyOffer = chat.get("offerUser").id === Parse.User.current().id;

  const user = chat.get(isMyOffer ? "requestUser" : "offerUser");

  const avatar = getProfileImage(user);

  const title = t(
    isMyOffer ? "rs:messenger.list.title_from" : "rs:messenger.list.title_to",
    {
      name: user.get("name"),
    }
  );

  const start = chat.get("start");
  const end = chat.get("end");

  const date = moment(chat.get("date")).format("DD.MM.");
  const time = chat.get("time");
  const s = `${start.get("type")} (${start.get("name")})`;
  const e = `${end.get("type")} (${end.get("name")})`;

  const description = `${date} @ ${time}, ${s} →  ${e}`;

  function blockUser() {
    Modal.confirm({
      title: t("rs:user.blacklist.confirm_title"),
      content: t("rs:user.blacklist.confirm_content"),
      okText: t("rs:user.blacklist.ok"),
      okType: "danger",
      onOk: () => {
        const blacklist = new UserBlacklist({
          user: Parse.User.current(),
          target: user,
        });

        blacklist.save().then(
          (ok) => {
            setChat(null);
            message.success(t("rs:user.blacklist.success"));
          },
          (error) => {
            console.error(error);
            message.success(t("rs:user.blacklist.error"));
          }
        );
      },
    });
  }

  function reportUser() {
    Modal.confirm({
      title: t("rs:user.report.confirm_title"),
      content: t("rs:user.report.confirm_content"),
      okText: t("rs:user.report.ok"),
      okType: "danger",
      onOk: () => {
        const report = new UserReport({
          user: Parse.User.current(),
          target: user,
          message: "",
        });

        report.save().then(
          (ok) => {
            setChat(null);
            message.success(t("rs:user.report.success"));
          },
          (error) => {
            console.error(error);
            message.success(t("rs:user.report.error"));
          }
        );
      },
    });
  }

  const menu = (
    React.createElement(Menu, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
      , React.createElement(Menu.Item, {
        key: "blacklist",
        onClick: blockUser,
        title: t("rs:user.blacklist.action_title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
      
        , React.createElement(Icon, { icon: "ai:stop", __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}} )
        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}, t("rs:user.blacklist.action"))
      )
      , React.createElement(Menu.Item, {
        key: "report",
        onClick: reportUser,
        title: t("rs:user.report.action_title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
      
        , React.createElement(Icon, { icon: "ai:exclamation-circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}} )
        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}, t("rs:user.report.action"))
      )
    )
  );

  return (
    React.createElement(List.Item, {
      onClick: () => {
        if (hasLink) {
          setChat(chat.id);
        }
      },
      actions: 
        hasLink
          ? [
              React.createElement(Button, {
                type: "link",
                icon: React.createElement(Icon, { icon: "ai:right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 128}} ),
                children: t("rs:messenger.list.open_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
              ),
            ]
          : [
              React.createElement(Dropdown, {
                overlay: menu,
                trigger: ["click"],
                children: 
                  React.createElement(Button, {
                    type: "default",
                    shape: "circle",
                    icon: React.createElement(Icon, { icon: "ai:more", __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
                  )
                , __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
              ),
            ]
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
    
      , React.createElement(List.Item.Meta, {
        title: title,
        description: description,
        avatar: 
          React.createElement(Avatar, {
            icon: avatar ? undefined : React.createElement(Icon, { icon: "ai:user", __self: this, __source: {fileName: _jsxFileName, lineNumber: 152}} ),
            src: avatar ? avatar : undefined,
            size: "large", __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}
      )
    )
  );
}
