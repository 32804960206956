const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\AppData.tsx";import { produce, useTranslation, useUserService } from "@opendash/core";
import { message } from "antd";
import Parse from "parse";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Chat, ErrorMessage, Message, useIsOnline } from "..";
import { RideSharingStateContext } from "../helper/RideSharingStateContext";

export function AppData({ children }) {
  const UserService = useUserService();
  const t = useTranslation();
  const online = useIsOnline();
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    error: null,
    loading: true,
    user: null,
    profile: null,
    chats: [],
    messages: [],
  });

  React.useEffect(() => {
    init();

    async function init() {
      try {
        console.log("RS init, online =", online);
        // Init user

        const user = Parse.User.current();

        if (online) {
          await user.fetchWithInclude(["tenant", "tenant.meta", "rsProfile"]);
        }

        // Init notifications

        if (online) {
          try {
            await UserService.registerWebPush();
          } catch (error) {
            console.error("Notification/Push Error", error);
          }
        }

        // Load chats

        const query = new Parse.Query(Chat)
          // TODO: Not working
          .descending("lastMessageAt")
          .include("offer")
          .include("requestUser")
          .include("offerUser")
          .include("requestUserProfile")
          .include("offerUserProfile")
          .include("start")
          .include("end");

        if (!online) {
          query.fromLocalDatastore();
        }

        const updateChats = async () => {
          const chats = await query.find();

          if (online) {
            await Parse.Object.pinAll(chats);
          }

          setState(
            produce((draft) => {
              draft.chats = chats;
            })
          );
        };

        await updateChats();

        if (online) {
          const chatSubscription = await query.subscribe();

          chatSubscription.on("open", () => {
            console.info("chatSubscription opened");
          });

          chatSubscription.on("create", (object) => {
            console.info("chat created");
            updateChats();
          });

          chatSubscription.on("update", (object) => {
            console.info("chat updated");
            updateChats();
          });

          chatSubscription.on("enter", (object) => {
            console.info("chat entered");
            updateChats();
          });

          chatSubscription.on("leave", (object) => {
            console.info("chat left");
            updateChats();
          });

          chatSubscription.on("delete", (object) => {
            console.info("chat deleted");
            updateChats();
          });

          chatSubscription.on("close", () => {
            console.info("chatSubscription closed");
          });
        }

        // Load messages

        const messageQuery = new Parse.Query(Message).ascending("createdAt");

        if (!online) {
          messageQuery.fromLocalDatastore();
        }

        const updateMessages = async () => {
          const messages = await messageQuery.find();

          if (online) {
            await Parse.Object.pinAll(messages);
          }

          setState(
            produce((draft) => {
              draft.messages = messages;
            })
          );
        };

        await updateMessages();

        if (online) {
          const messagesSubscription = await messageQuery.subscribe();

          messagesSubscription.on("open", () => {
            console.info("messagesSubscription opened");
          });

          messagesSubscription.on("create", (object) => {
            console.info("messages created");
            updateMessages();
          });

          messagesSubscription.on("update", (object) => {
            console.info("messages updated");
            updateMessages();
          });

          messagesSubscription.on("enter", (object) => {
            console.info("messages entered");
            updateMessages();
          });

          messagesSubscription.on("leave", (object) => {
            console.info("messages left");
            updateMessages();
          });

          messagesSubscription.on("delete", (object) => {
            console.info("messages deleted");
            updateMessages();
          });

          messagesSubscription.on("close", () => {
            console.info("messagesSubscription closed");
          });
        }

        setState(
          produce((draft) => {
            draft.loading = false;
            draft.error = null;
          })
        );
      } catch (error) {
        if (error.code === 209) {
          message.error("Deine Session ist abgelaufen.");

          return void Parse.User.logOut().finally(() => {
            window.location.reload();
          });
        }

        console.error("Error while loading app data:");
        console.error(error);

        setState(
          produce((draft) => {
            draft.loading = false;
            draft.error = error;
          })
        );
      }
    }
  }, [online]);

  React.useEffect(() => {
    const profile = Parse.User.current().get("rsProfile");

    if (profile.get("showIntro")) {
      navigate("/ride-sharing/intro");
    }
  }, []);

  if (state.loading) {
    return null;
  }

  if (state.error) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: t("rs:app.data_error_title"),
        message: t("rs:app.data_error_subtitle"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 221}}
      )
    );
  }

  return React.createElement(RideSharingStateContext.Provider, { value: state, children: children, __self: this, __source: {fileName: _jsxFileName, lineNumber: 229}} );
}
