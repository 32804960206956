const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\ride-sharing-frontend\\app\\js\\ride-sharing-app\\components\\UserPredefinedSearchCreate.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation, useUrlParam } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Input, message } from "antd";
import React from "react";
import {
  FormElement,
  LocationInput,
  Search,
  TimeInput,
  useRideSearchEnd,
  useRideSearchStart,
  useRideSearchTime,
  WeekdayInput,
} from "..";

const containerStyle = {
  padding: 24,
};

const switchButtonStyle = {
  display: "block",
  zIndex: 1,
  marginTop: -28,
  marginBottom: -4,
  marginLeft: "auto",
  marginRight: 0,
};

const inputWithLabelStyle = {
  width: "100%",
};

const submitButtonStyle = {
  width: "100%",
  // marginTop: 14,
  marginBottom: 24,
};

export function UserPredefinedSearchCreate() {
  const t = useTranslation();

  const [view, setView] = useUrlParam("view", null);

  const [title, setTitle] = React.useState("");

  const [start, setStart] = useRideSearchStart();
  const [end, setEnd] = useRideSearchEnd();
  const [time, setTime] = useRideSearchTime();

  const [weekdays, setWeekdays] = React.useState(
    [1, 2, 3, 4, 5]
  );

  return (
    React.createElement('div', { style: containerStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
      , React.createElement(FormElement, { label: t("rs:ride.search_title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
        , React.createElement(Input, {
          size: "large",
          placeholder: t("rs:ride.search_title_placeholder"),
          value: title,
          onChange: (e) => setTitle(e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
        )
      )

      , React.createElement(FormElement, { label: t("rs:ride.start_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        , React.createElement(LocationInput, {
          style: inputWithLabelStyle,
          label: t("rs:ride.start_select"),
          urlParam: "select_start",
          location: start,
          setLocation: (location) => setStart(location), __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
        )
      )

      , React.createElement(Button, {
        size: "large",
        shape: "circle",
        type: "primary",
        style: switchButtonStyle,
        icon: React.createElement(Icon, { icon: "ai:retweet", __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}} ),
        title: t("rs:search.switch"),
        disabled: !start || !end,
        onClick: (e) => {
          setStart(end);
          setEnd(start);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
      )

      , React.createElement(FormElement, { label: t("rs:ride.end_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
        , React.createElement(LocationInput, {
          style: inputWithLabelStyle,
          label: t("rs:ride.end_select"),
          urlParam: "select_end",
          location: end,
          setLocation: (location) => setEnd(location), __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
        )
      )

      , React.createElement(FormElement, { label: t("rs:weekdays.label"), useLabel: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
        , React.createElement(WeekdayInput, { value: weekdays, setValue: setWeekdays, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}} )
      )

      , React.createElement(FormElement, { label: t("rs:ride.time_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
        , React.createElement(TimeInput, { time: time, setTime: setTime, style: inputWithLabelStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}} )
      )

      , React.createElement(Button, {
        size: "large",
        type: "primary",
        style: submitButtonStyle,
        disabled: !start || !end || !_optionalChain([weekdays, 'optionalAccess', _ => _.length]),
        children: t("rs:search.create.submit"),
        title: 
          !start || !end || !_optionalChain([weekdays, 'optionalAccess', _2 => _2.length])
            ? t("rs:search.create.submit_tooltip_disabled")
            : t("rs:search.create.submit_tooltip")
        ,
        onClick: (e) => {
          const search = new Search({
            title,
            start,
            end,
            time,
            weekdays,
            public: false,
          });

          search.save().then(
            () => {
              message.success(t("rs:search.create.success"));
              setView("list");
            },
            (error) => {
              message.error(t("rs:search.create.error"));
              console.error(error);
            }
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
      )
    )
  );
}
